import React, { Component } from "react";

import Nav from "../components/Nav";
import TransitionElement from "../components/TransitionElement";
import Button from "../components/Button";
import Header from "../components/Header";
import Footer from "../components/Footer";
import MainContent from "../components/MainContent";
import SecondaryContent from "../components/SecondaryContent";
import ListContent from "../components/ListContent";
class Consultants extends Component {
  constructor() {
    super();
    this.state = {
      active: false,

    };
  }

  componentDidMount() {
    
  }
  contentLoaded() {
    setTimeout(() => {
      this.setState({ active: true });
    }, 0);
  }


  render() {
    const { active } = this.state;
    return (
      <div>
        <Nav />
        <section className="body-content">
          <TransitionElement mColor={"white"} sColor={"#2EA399"} />
          <Header active={active} height={360} heading="For Consultants" />
          <MainContent
            id={72}
            contentLoaded={this.contentLoaded.bind(this)}

          />
          <Footer active={active} />
        </section>
      </div>
    );
  }
}

export default Consultants;
