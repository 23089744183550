import React, { Component } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
// import { Router } from "react-router";

import HomePage from "./Pages/HomePage";
import AboutPage from "./Pages/AboutPage";
import Clients from "./Pages/Clients";
import Consultants from "./Pages/Consultants";
import Blog from "./Pages/Blog";
import VerveBlog from './Pages/VerveBlog';
import Contact from "./Pages/Contact";
import Privacy from "./Pages/Privacy";
import Terms from "./Pages/Terms";
import Events from "./Pages/Events";
import history from "./history";
import News from "./Pages/News";
// import LoadAnimation from "./components/LoadAnimation";

const Views = () => {
  return (
    <Router history={history} basename={'/'}>
      <Routes>
        <Route path="/" element={<HomePage/>} />
        <Route path="/about" element={<AboutPage/>} />
        <Route path="/clients" element={<Clients/>} />
        <Route path="/consultants" element={<Consultants/>} />
        <Route path="/events" element={<Events/>} />
        <Route path="/news" element={<News/>} />
        <Route path="/blog" element={<VerveBlog/>}/> 
        <Route path="/contact" element={<Contact/>} />
        <Route path="/privacy-policy" element={<Privacy/>} />
        <Route path="/terms-and-conditions" element={<Terms/>} />
        <Route path="/news/:slug" element={<News/>} />
        <Route path="/blog/:slug" element={<Blog/>} />
      </Routes>
    </Router>
  );
};

// class Controller extends Component {
//   constructor() {
//     super();
//     this.state = {
//       element_display: <Views />,
//       loading: true
//     };
//     this.handleLoading = this.handleLoading.bind(this);
//   }

//   handleLoading() {
//     let cmp = this;
//     setTimeout(function() {
//       cmp.setState({ element_display: <Views /> });
//     }, 4000);
//   }

//   componentDidMount() {
//     //this.handleLoading();
//   }

//   render() {
//     return (
//       <Router history={history}>
//         <Switch>{this.state.element_display}</Switch>
//       </Router>
//     );
//   }
// }

export default Views;
