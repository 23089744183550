import React from "react";
import Button from "./Button";
import Verve from "../assets/verve.png";

const linksStyle = {
  fontWeight: 900,
  fontFamily: "NeueEinstellung",
  color: "#000",
};

const Copyright = props => {
  return (
    <section className={"copyright " + (props.active ? "display" : "hide")}>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="copyright-container">
              <img src={Verve} alt="verve"/>
              <p className="copyright-text" >
                Copyright © Verve Group Consulting. All Rights Reserved |
                <Button
                  className=""
                  link="/privacy-policy"
                  text=" Privacy Policy "
                  style={linksStyle}
                />
                |
                <Button
                  className=""
                  link="/terms-and-conditions"
                  text=" Terms and Conditions"
                  style={linksStyle}
                />

              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Copyright;
