import React from "react";
import RightLight from "../assets/chevron-right-solid-light.svg";
import RightDark from "../assets/chevron-right-solid-dark.svg";
import { Link } from 'react-router-dom'

import history from "../history";

const Button = props => {
  function runTransition() {
    /* Hide mobile nav on mobile */
    const mobileNav = document.getElementById("nav-hamburger");
    const mobileContent = document.getElementById("expanded-content");
    if (mobileNav && mobileContent) {
      mobileNav.classList.remove("expanded");
      mobileContent.classList.remove("active");
    }
    if (window.outerWidth > 10000) {
      let transition = document.getElementById("transition-main");
      let transition_S = document.getElementById("transition-secondary");
      transition.classList.remove("anim-done");
      transition_S.classList.remove("anim-done");
      transition.classList.add("anim-transition");
      transition_S.classList.add("anim-transition");

      setTimeout(function() {
        transition.classList.add("anim-done");
        transition_S.classList.add("anim-done");
        transition.classList.remove("anim-transition");
        transition_S.classList.remove("anim-transition");
        history.push(props.link);

      }, 850);
    } else {
      history.push(props.link);
    }
    window.scrollTo(0, 0);
  }

  return (
    <span className={"custom-a " + (props.className ? props.className : "")}>
      <Link to={props.link} className="clickEl" onClick={runTransition} style={props.style}>
        {props.text}
        {props.arrow ? <img className="cta-arrow" src={(props.light ? RightLight: RightDark)} style={{height: props.arrowHeight}} /> : ""}

      </Link>

    </span>
  );
};

export default Button;
