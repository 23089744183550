import React, { Component } from "react";

import TransitionElement from "../components/TransitionElement";
import Nav from "../components/Nav";
import TypeWriter from "../components/TypeWriter";
import MainContent from "../components/MainContent";

import Footer from "../components/Footer";
import FlipCards from "../components/FlipCards";
import axios from 'axios';

import ReactHtmlParser from 'react-html-parser';
class HomePage extends Component {
  constructor() {
    super();
    this.state = {
      active: false,
      secondaryContent: ""
    };
  }

  componentDidMount() {
    var self = this;

    axios.get('https://cms.vervegroupconsulting.com/wp-json/wp/v2/pages/59')
    .then(function(res) {
      self.setState({
        secondaryContent: res.data.acf.secondary_content
      })
    })
    .catch(function(err) {
      console.log(err);
    });
  }
  contentLoaded() {
    setTimeout(() => {
      this.setState({ active: true });
    }, 0);
  }

  render() {
    let {active} = this.state;
    return (
      <div>
        <Nav />
        <section className="body-content">
          <TransitionElement mColor={"white"} sColor={"#2EA399"} />
          <TypeWriter />
          <FlipCards />
          <MainContent
            id={59}
            contentLoaded={this.contentLoaded.bind(this)}

          />
          <section className="content">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  {ReactHtmlParser(this.state.secondaryContent)}
                </div>
              </div>
            </div>
          </section>
          <Footer active={active} />
        </section>
      </div>
    );
  }
}

export default HomePage;
