import React, { Component } from "react";
import Typist from "react-typist";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2
} from "react-html-parser";

class TypeWriter extends Component {
  constructor() {
    super();
    this.state = {
      typing: true,
      phrase_index: 0,
      para_index: -1,
      phrases: [
        "Privacy & <br />Compliance",
        "Affordable <br />Expertise",
        "The Power of <br />Collaboration"
      ],
      currentPhrases: "",
      intervalID: "",
      paragraphs: [
        "Keep pace with evolving laws and focus on your growing business.",
        "We don’t charge for our brand, only for what we deliver.",
        "Control deliverables, deadlines, budgets and billing arrangements."
      ],
      firstAnim: 0,
      secondAnim: 0,
      active: false,
      lineCounter: 0,
      done: false
    };
    this.rotateText = this.rotateText.bind(this);
  }

  componentDidMount() {
    let intervalID = setTimeout(this.rotateText, 5000);
    setTimeout(() => this.setState({ intervalID, active: true }), 0);
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  rotateText() {
    let { phrase_index, phrases, currentPhrases } = this.state;
    phrase_index++;
    currentPhrases += phrases[phrase_index];
    this.setState({ phrase_index, currentPhrases });
  }

  createMarkup(s) {
    return {
      __html: s
    };
  }
  doBackSpace(phrase) {
    return (
      <Typist.Backspace
        count={phrase.length}
        delay={5000 - phrase.length * 25}
      />
    )
  }
  typingDone = () => {
    let {para_index, done} = this.state;
    if(!done){
      this.setState({ typing: false }, () => {
        this.setState({ typing: true });
      });
    }
  };

  lineDone = line => {
    let { firstAnim, secondAnim, para_index, lineCounter, done } = this.state;

    if (!done) {
      firstAnim = (firstAnim + 1) % 3;
      secondAnim = (secondAnim + 1) % 3;
      lineCounter = (lineCounter + 1) % 3;
      if(lineCounter == 1 && para_index == 1) {
        done = true;
      }
      if ( lineCounter == 1 ) {
        para_index = (para_index + 1) % 3;
      }
    }


    this.setState({ firstAnim, secondAnim, para_index, lineCounter, done });
  };

  render() {
    const { active, phrases } = this.state;
    let activeClass = active ? " active" : "";
    return (
      <section className="typewriter">
        <div className="typewriter-wrapper">
          <div className="typewriter-grid">
            <div className={"typewriter-heading" + activeClass}>
              {this.state.typing ? (
                <Typist
                  avgTypingDelay={25}
                  stdTypingDelay={0}
                  onTypingDone={this.typingDone}
                  onLineTyped={this.lineDone}
                  cursor={{ show: false }}
                >
                  {phrases.map((phrase, index) => (
                    <section key={index}>
                      <h2 className="typewriter-heading-text" id="typist">
                        {ReactHtmlParser(phrase)}
                      </h2>

                      {
                          index!=2 ? this.doBackSpace(phrase) : ""
                      }
                    </section>
                  ))}
                </Typist>
              ) : (
                ""
              )}
            </div>
            <div className="typewriter-bar">
              <div className="white-bar">
                <div
                  className={
                    "progress-bar " +
                    (this.state.firstAnim == 2 && this.done!=true ? "animateBar" : "") +
                    (this.state.secondAnim == 0 || this.done==true ? "animateBar-up" : "")
                  }
                />
              </div>
            </div>
            <div className="typewriter-content">


              <p
                className={
                  "typewriter-content-text " +
                  (this.state.secondAnim ? "active " : "") +
                  (this.state.done==true ? "done" : "")

                }
              >
                {this.state.paragraphs[this.state.para_index]}
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default TypeWriter;
