import React from "react";
import Acg from "../assets/acg.png";
import Bio from "../assets/bio.png";
import Iapp from "../assets/iapp.png";

const Partners = props => {
  return (
    <section className={"partners " + (props.active ? "display" : "hide")}>
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <h2>Proudly associated with the following organizations:</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-4 text-center">
            <a href="https://www.acg.org/newjersey">
              <img className="partner-logo" src={Acg} alt="acg"/>
            </a>
          </div>
          <div className="col-sm-4 text-center">
          <a href="https://bionj.org/about-bionj">
            <img className="partner-logo" src={Bio} alt="bio"/>
          </a>
          </div>
          <div className="col-sm-4 text-center">
          <a href="https://iapp.org/">
            <img className="partner-logo" src={Iapp} alt="Iapp"/>
          </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Partners;
