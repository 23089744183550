import React, { Component } from "react";
import MoreInfo from "../components/MoreInfo";
import Nav from "../components/Nav";
import TransitionElement from "../components/TransitionElement";
import Button from "../components/Button";
import Header from "../components/Header";
import Footer from "../components/Footer";
import NewsItems from "../components/NewsItems";
import axios from "axios";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import Partners from "../components/Partners";
import FooterLinks from "../components/FooterLinks";
import Copyright from "../components/Copyright";
import Brands from "../assets/brands.png";
import Vendor from "../assets/vendor.png";
import Evaluation from "../assets/evaluation.png";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

const background1 = {
  backgroundImage: `url(${Brands})`
};
const background2 = {
  backgroundImage: `url(${Vendor})`
};
const background3 = {
  backgroundImage: `url(${Evaluation})`
};

class VerveBlog extends Component {
  constructor() {
    super();
    this.state = {
      prev: [],
      active: false,
      loading: true,
      newsItems: [],
      itemCount: 0,
      page: 1,
      pages: 0, 
      startingIndex: 0,
      endingIndex: 12, 
    };
    
  }

  
  fetchArchive = (input = null) => {
    var newsItems = [];
    const loading = false;
    var self = this;
    const prev = this.state.prev

    const page = `&page=${input}`

    axios.get(`https://cms.vervegroupconsulting.com/wp-json/wp/v2/posts?per_page=100${
      input ? page : ''
    }`)
    .then(function(res) {
      res.data.map( (data) => (
        newsItems.push({ 
          ...prev,
          id: data.id, 
          date: data.date, 
          slug: data.slug, 
          title: data.title.rendered, 
          link: data.acf.link, 
          image: data.acf.image, 
          internal: data.acf.internal 
        })
      ));

      let featuredArticles = newsItems.slice(0, 13)
      let sortedArticles = newsItems.slice(13).sort(function(a,b){
        // return new Date(b.date) - new Date(a.date);
        return newsItems;
      });

      let combinedArticles = [...featuredArticles, ...sortedArticles];

      const items = newsItems.length
      const maxPerPage = 12
      const pages = Math.ceil(items / maxPerPage)

      self.setState({ prev: newsItems, newsItems: combinedArticles, loading, pages });
      self.setState({ active: true });
      self.setState({ itemCount: newsItems.length })
        
    })
    .catch(function(err) {
      console.log(err);
    });
    
  }

  componentDidMount() {
    this.fetchArchive()
    if(this.state.itemCount === 100) {
      this.fetchArchive(2)
    }
  }

  renderExternal(title, link, image) {

    return (
      <a className="media-card-link" target="_blank" href={link}>
        <div className="media-card" style={{backgroundImage: `url(${image})`}}>
          <div className="media-card-overlay" />
          <div className="media-card-content">
            <p>
              {ReactHtmlParser(title)}
            </p>
          </div>
        </div>
      </a>
    )
  }
  renderInternal(title, slug, image, date) {
    
    const parseDate = () => {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",      
        "August",
        "September",
        "October",
        "November",
        "December"
      ]

      const monthDayYear = date.split('-', 3)
      const month = months[parseInt(monthDayYear[1]) - 1]
      const year = monthDayYear[0]


      return `${month}, ${year}`
    }

    return (
      <Link className="media-card-link" to={"/blog/" + slug}>
        <div className="media-card" style={{backgroundImage: `url(${image})`}}>
          <div className="media-card-overlay" />
          <div id="inner-blog-square" className="media-card-content">
            <div className="top-half">
              {parseDate()}
            </div>
            <div className="bottom-half">
              <p>
                {ReactHtmlParser(title)}
              </p>
            </div>
          </div>
        </div>
      </Link>
    )
  }

  paginateForward(){
    let itemsPerPage = 12
    let page = this.state.page

    page += 1

    this.setState({
      startingIndex: this.state.startingIndex + itemsPerPage,
      endingIndex: this.state.endingIndex + itemsPerPage,
      page
    }) 

    window.scrollTo(0,0)
  }

  paginateBackward(){
    let itemsPerPage = 12
    let page = this.state.page

    page -= 1

    this.setState({
      startingIndex: this.state.startingIndex - (itemsPerPage),
      endingIndex: this.state.endingIndex - (itemsPerPage),
      page
    }) 

    window.scrollTo(0,0)
  }

  renderCards() {
    let self = this;

    const startingIndex = this.state.startingIndex
    const endingIndex = this.state.endingIndex

    return (
      this.state.newsItems.slice(startingIndex, endingIndex).map(({ id, title, slug, link, image, internal, date }) => (
        <div id="article" className="col-lg-4 text-center" key={id}>
          {
            (internal == true ? self.renderInternal(title,slug,image, date) : self.renderExternal(title,link,image))
          }
        </div>
      ))
    )
  }
  render(startingIndex = 0,
    endingIndex = this.itemsPerPage) {
    const { active, newsItems } = this.state;
    return (
      <div>
        <Nav />
        <section className="body-content">
          <TransitionElement mColor={"white"} sColor={"#2EA399"} />
          <Header active={active} height={360} heading={"Blog"} />
          <section className={"media " + (active ? "display" : "hide")}>
            <div id="blog-container" className="container">
              <div className="row article-container">
                {!this.state.loading ? this.renderCards(startingIndex, endingIndex) : null}
              </div>
            </div>
          </section>
          {!this.state.loading && <MoreInfo page='blog' />}
          {!this.state.loading && (
            <div className="paginate-container">
              <div id="paginate" className="container">
                <button
                  style={{ display: this.state.page > 1 ? 'block' : 'none'}} 
                  onClick={() => this.paginateBackward()} 
                  className="paginate back"
                  >
                  Back
                </button>
                <div className="page-indicator">
                  <p>Page {this.state.page} of {this.state.pages}</p>
                </div>
                <button 
                  style={{ display: this.state.page === this.state.pages ? 'none' : 'block'}}
                  onClick={() => this.paginateForward()} 
                  className="paginate next"
                  >
                  Next
                </button>
              </div>
            </div>
          )}
          <Partners active={active} />
          <FooterLinks active={active} />
          <Copyright active={active}/>
        </section>
      </div>
    );
  }
}

export default VerveBlog;