import React, { Component } from "react";

import Nav from "../components/Nav";
import TransitionElement from "../components/TransitionElement";
import Button from "../components/Button";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ContactContent from "../components/contactContent";
import axios from "axios";
class Contact extends Component {
  constructor() {
    super();
    this.state = {
      active: false,
      heading: "",
      contentLeft: `

      `,
      contentRight: `

      `,
      phone: "908-884-0379",
      email: "lsamilow@vervegroupconsulting.com"
    };
  }

  componentDidMount() {
    let self = this;
    axios.get('https://cms.vervegroupconsulting.com/wp-json/wp/v2/pages/120')
    .then(function(res) {
      self.setState({
        heading: res.data.acf.heading,
        contentLeft: res.data.acf.left,
        contentRight: res.data.acf.right,
        active: true
      })
    })
    .catch(function(err) {
      console.log(err);
    });
  }

  render() {
    const { active, heading, contentLeft, contentRight, phone, email } = this.state;
    return (
      <div>
        <Nav />
        <section className="body-content">
          <TransitionElement mColor={"white"} sColor={"#2EA399"} />
          <Header active={active} height={360} heading="Contact" />
          <ContactContent
            heading={heading}
            contentLeft={contentLeft}
            contentRight={contentRight}
            phone={phone}
            email={email}
            active={active}
          />
          <Footer active={active} />
        </section>
      </div>
    );
  }
}

export default Contact;
