import React, { Component } from "react";


import Button from "./Button";
import LogoWhite from "../assets/logowhite.png";
class Nav extends Component {
  constructor() {
    super();
    this.state = {
      active: false,
      links: [
        { text: "Home", link: "/" },
        { text: "About", link: "/about" },
        { text: "Clients", link: "/clients" },
        { text: "Consultants", link: "/consultants" },
        { text: "News", link: "/news" },
        { text: "Blog", link: "/blog" },
        { text: "Contact", link: "/contact" }
      ],
      path: "",
      mobileExpanded: false
    };
    this.toggleExpand = this.toggleExpand.bind(this);
    this.getActiveLink = this.getActiveLink.bind(this);
  }

  componentDidMount() {
    this.getActiveLink();
    setTimeout(() => {
      this.setState({ active: true });
    }, 0);
  }

  toggleExpand() {
    this.setState({ mobileExpanded: !this.state.mobileExpanded });
  }

  getActiveLink() {
    try {
      let path = window.location.href.split("/")[3];
      path = path === "" ? "home" : path;
      this.setState({ path });
    } catch (e) {
      this.setState({ path: "" });
    }
  }

  render() {
    const { links, active, path, mobileExpanded } = this.state;
    let navActive = active ? "active" : "";
    let mobileNav = mobileExpanded ? "nav-hamburger expanded" : "nav-hamburger";
    let expandedContent = mobileExpanded
      ? "expanded-content active"
      : "expanded-content";
    return (
      <div className="header-wrapper">
        <div className={"header-navbar " + navActive}>
          <div className="header-logo">
            <a href="/">
              <img src={LogoWhite} />
              <div className="logo-text"><h3>Verve Group<br /> Consulting</h3></div>
            </a>
          </div>
          <div className="header-nav">
            <ul className="nav-list">
              {links.map((l, index) => (
                <li key={index} className="nav-item">
                  <Button
                    className={
                      "nav-link " +
                      (path === l.text.toLowerCase() ? "nav-active-link" : "")
                    }
                    link={l.link}
                    text={l.text}
                  />
                </li>
              ))}
            </ul>
            <section
              onClick={this.toggleExpand}
              id="nav-hamburger"
              className={mobileNav}
            >
              <span className="nav-hamburger-dash" />
              <span className="nav-hamburger-dash" />
              <span className="nav-hamburger-dash" />
            </section>
          </div>
        </div>
        <section id="expanded-content" className={expandedContent}>
          {links.map((l, index) => (
            <div key={index} className="exanded-link-container">
              <Button
                className={"nav-expanded-link"}
                link={l.link}
                text={l.text}
              />
            </div>
          ))}
        </section>
      </div>
    );
  }
}

export default Nav;
