import 'core-js/stable'
import React from "react";
import { render } from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

const root = document.getElementById("root")

render(<App />, root);

serviceWorker.unregister();
