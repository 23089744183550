import React from "react";
import Brands from "../assets/brands.png";
import Vendor from "../assets/vendor.png";
import Evaluation from "../assets/evaluation.png";

const background1 = {
  backgroundImage: `url(${Brands})`
};
const background2 = {
  backgroundImage: `url(${Vendor})`
};
const background3 = {
  backgroundImage: `url(${Evaluation})`
};

const NewsItems = props => {
  return (
    <section className="media">
      <div className="container">
        <div className="row">
          {props.newsItems.map(({ link, img }) => (
            <p>{link}</p>

          ))}
          <div className="col-lg-4 text-center">
            <a className="media-card-link" href="">
              <div className="media-card" style={background1}>
                <div className="media-card-overlay" />
                <div className="media-card-content">
                  <p>
                    Plans for Data Privacy and Protection? Start with Vendor
                    Contracts
                  </p>
                </div>
              </div>
            </a>
          </div>
          <div className="col-lg-4 text-center">
            <a className="media-card-link" href="">
              <div className="media-card" style={background2}>
                <div className="media-card-overlay" />
                <div className="media-card-content">
                  <p>
                    Avoid Acquiring a Data "Lemon." Include Data Privacy &
                    Protection as Part of Due Diligence
                  </p>
                </div>
              </div>
            </a>
          </div>
          <div className="col-lg-4 text-center">
            <a className="media-card-link" href="">
              <div className="media-card" style={background3}>
                <div className="media-card-overlay" />
                <div className="media-card-content">
                  <p>
                    Transparent Data Privacy & Protection: A Boost to Brand
                    Image & Customer Trust
                  </p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewsItems;
