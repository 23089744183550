import React from "react";
import Partners from "./Partners";
import Media from "./Media";
import FooterLinks from "./FooterLinks";
import Copyright from "./Copyright";
import MoreInfo from "./MoreInfo";

const Footer = props => {
  return (
    <footer className={"custom-footer " + (props.active ? "display" : "hide")}>
      <Partners active={props.active}/>
      <Media />
      <FooterLinks active={props.active}/>
      <MoreInfo/>
      <Copyright active={props.active} />
    </footer>
  );
};

export default Footer;
