import React, { Component } from "react";

import Nav from "../components/Nav";
import TransitionElement from "../components/TransitionElement";
import Button from "../components/Button";
import Header from "../components/Header";
import Footer from "../components/Footer";
import TeamMember from "../components/TeamMember";
import TeamMemberContent from "../components/TeamMemberContent";
import axios from 'axios';
import ReactHtmlParser from 'react-html-parser';
import Placeholder from "../assets/placeholder.png";
class AboutPage extends Component {
  constructor() {
    super();
    this.state = {
      active: false,
      opening_content: "",
      team: []
    };
  }

  componentDidMount() {
    let self = this;

    axios.get('https://cms.vervegroupconsulting.com/wp-json/wp/v2/pages/118')
    .then(function(res) {
      self.setState({
        opening_content: res.data.acf.opening_content,
        team: res.data.acf.team,
        active: true
      })
    })
    .catch(function(err) {
      console.log(err);
    });

  }

  render() {

    const { active } = this.state;
    const larryContent = `
      <p>With a 25+ year track record of helping corporations achieve their commercial goals through innovative, proportionate and cost-effective legal solutions, Larry Samilow, co-founder and managing director of Verve Group Consulting, is a valued resource.</p>
      <p>He is a member of the International Association of Privacy Professionals. BioNJ and Association of Corporate Growth New Jersey.</p>
      <p>Larry has been known for helping companies in the New Jersey metro region to grow through the use of innovative legal service solutions.. During his impressive career, he served as the chief customer officer of a national legal service provider, founded  the New Jersey General Counsel of the Year Awards, and has been an active leader in several innovative legal industry networking groups and CLEs.</p>
      <p>Additionally, Larry is a former board member of Prevent Child Abuse New Jersey and Jewish Family Services of Somerset County and is a former honorary hairman of the Newark Area Fresh Air Fund.</p>
      <p><a href="https://www.linkedin.com/in/larry-samilow-041a531a/">Link with Larry....</p>
    `;
    const joshContent = `
      <p>An innovator in the Alternative Legal Services Provider business, Josh Schott founded one of the largest ALSPs in the United States. In fact, in his 25 years in the business, he created numerous innovations in the Alternative Legal Services Provider industry that are still utilized today.</p>
      <p>Josh was the main strategist for the service provided to over 500 client companies and thousands of consultants, and was the lead representative for the company he led in two private equity transactions.</p>
      <p>Josh is a member of the International Association of Privacy Professionals. BioNJ and Association of Corporate Growth New Jersey, and has held seats on the boards of a number of national charitable organizations.</p>
    `;

    return (
      <div>
        <Nav />
        <section className="body-content">
          <TransitionElement mColor={"white"} sColor={"#2EA399"} />
          <Header active={active} height={360} heading="About" />
          <section className={"about-intro " + (active ? "display" : "hide")}>
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  {ReactHtmlParser(this.state.opening_content)}
                </div>
              </div>
            </div>

          </section>
          <section className={"team-member " + (active ? "display" : "hide")}>
            <div className="container">
              <div className="row">
                {
                  this.state.team.map( (member) => (
                    <div className="col-lg-6">
                      <div className="team-member-content">
                        <h2 className="title">{member.title}</h2>
                        <h3 className="subtitle">{ReactHtmlParser(member.links)}</h3>
                        <hr align="left" />
                        <p className="content">{ReactHtmlParser(member.content)}</p>
                      </div>
                    </div>
                  ))
                }

              </div>
            </div>
          </section>
          <Footer active={active} />
        </section>
      </div>
    );
  }
}

export default AboutPage;
