import React, { Component } from "react";
import Nav from "../components/Nav";
import TransitionElement from "../components/TransitionElement";
import Button from "../components/Button";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ReactHtmlParser from "react-html-parser";
import axios from "axios";

class Privacy extends Component {
  constructor() {
    super();
    this.state = {
      active: false,
      content: ""
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ active: true });
    }, 0);
    var self = this;
    var url = "https://cms.vervegroupconsulting.com/wp-json/wp/v2/pages/76";
    axios.get(url)
    .then(function(res) {
      self.setState({
        content: res.data.acf.content
      })
    })
    .catch(function(err) {
      console.log(err);
    });
  }

  render() {
    const { active, heading, content, phone, email } = this.state;
    return (
      <div>
        <Nav />
        <section className="body-content">
          <TransitionElement mColor={"white"} sColor={"#2EA399"} />
          <Header active={active} height={500} heading="Privacy Policy" />
          <section className="legal-copy">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  {ReactHtmlParser(this.state.content)}
                </div>
              </div>
            </div>
          </section>
          <Footer active={active} />
        </section>
      </div>
    );
  }
}

export default Privacy;
