import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

const MoreInfo = ({ page }) => {

    const [width, setWidth] = useState(null)

    useEffect(() => {
        const width = window.screen.width
        setWidth(width)
    },[])

    const renderViews = () => {
        if (width > 481) {
            return(
                <section className='more-info'>
                    {page !== 'blog' && (
                    <div className="text-box">
                        <p>For more  articles Worth Reading, please <br/> check out the Verve Group Consulting 
                          <Link  to="/blog"> blog</Link></p>
                    </div>
                    )}
                    {page === 'blog' ? (
                    <div className="text-box">
                       <p>Connect with Larry Samilow, Verve Group Consulting's Co-Founder & Managing Director, by visiting his LinkedIn <a href="https://www.linkedin.com/in/larry-samilow-041a531a">profile</a></p>
                    </div> 
                    ) : (
                    <div className="text-box">
                        <p>To connect on LinkedIn, please <br/> visit Larry Samilow's <a href="https://www.linkedin.com/in/larry-samilow-041a531a">profile</a></p>
                    </div>
                    )}
                    
                </section>
            )
        } else {
            return(
                <section className='more-info'>
                    {page !== 'blog' && (
                    <div className="text-box">
                        <p>
                            For more  articles Worth Reading, please check out the Verve Group Consulting <Link to="/blog">blog</Link>
                        </p>
                    </div>
                    )}
                    <div className="text-box">
                        <p>
                            To connect on LinkedIn, please visit Larry Samilow's <a href="https://www.linkedin.com/in/larry-samilow-041a531a">profile</a>
                        </p>
                    </div>
                </section>
            )
        }
    }

  return (
    <>
       {renderViews()}
    </>
  )
}

export default MoreInfo