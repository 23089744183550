import React, { Component } from "react";

import Nav from "../components/Nav";
import TransitionElement from "../components/TransitionElement";
import Button from "../components/Button";
import Header from "../components/Header";
import Footer from "../components/Footer";
import MainContent from "../components/MainContent";
import SecondaryContent from "../components/SecondaryContent";
import ListContent from "../components/ListContent";

class Clients extends Component {
  constructor() {
    super();
    this.state = {
      active: false,
      mainContent: {
        title: "Is Verve Group Consulting Right for You?",
        subtext: "Do you have legal expertise in Data Privacy and Protection, Compliance & Ethics, Regulatory or Contracts issues?",
        subtitle: "Your legal expertise is in demand.",
        content: `
        <p>Bibendum enim facilisis gravida neque convallis a. Facilisis magna etiam tempor orci eu. Enim tortor at auctor urna. Egestas erat imperdiet sed euismod nisi. Libero nunc consequat interdum varius sit. Turpis massa sed elementum tempus egestas. Augue eget arcu dictum varius. Neque sodales ut etiam sit amet nisl purus. Egestas maecenas pharetra convallis posuere morbi leo urna. Tellus at urna condimentum mattis pellentesque id nibh.</p>
        <p>Bibendum enim facilisis gravida neque convallis a. Facilisis magna etiam tempor orci eu. Enim tortor at auctor urna. Egestas erat imperdiet sed euismod nisi. Libero nunc consequat interdum varius sit. Turpis massa sed elementum tempus egestas. Augue eget arcu dictum varius. Neque sodales ut etiam sit amet nisl purus. Egestas maecenas pharetra convallis posuere morbi leo urna. Tellus at urna condimentum mattis pellentesque id nibh.</p>
        <p><strong>Bibendum enim facilisis gravida neque convallis a. Facilisis magna etiam tempor orci eu. Enim tortor at auctor urna. Egestas erat imperdiet sed euismod nisi. Libero nunc consequat interdum varius sit. Turpis massa sed elementum tempus egestas. Augue eget arcu dictum varius. Neque sodales ut etiam sit amet nisl purus. Egestas maecenas pharetra convallis posuere morbi leo urna. Tellus at urna condimentum mattis pellentesque id nibh.</strong></p>

        `,
        card_list: [
          "Data Privacy, Compliance & Ethics, Regulatory and Contracts Expertise.",
          "Keep Pace with the Evolving Laws, to Keep Pace with Your Growing Business.",
          "Former Big 4, AmLaw and Fortune 1000 Experts.",
          "Control the Deliverable, Deadline and Budget.",
          "Benchmarking, Risk Assessment, Auditing, Policies & Procedures, Training and Monitoring and Board and Deal Due Diligence."
        ]
      }
    };
  }

  componentDidMount() {

  }
  contentLoaded() {
    setTimeout(() => {
      this.setState({ active: true });
    }, 0);
  }

  render() {
    const {
      title,
      subtext,
      subtitle,
      content,
      card_list
    } = this.state.mainContent;
    const { active } = this.state;
    return (
      <div>
        <Nav />
        <section className="body-content">
          <TransitionElement mColor={"white"} sColor={"#2EA399"} />
          <Header active={active} height={360} heading="For Clients" />
          <MainContent
            id={66}
            contentLoaded={this.contentLoaded.bind(this)}
          />

          <Footer active={active}/>
        </section>
      </div>
    );
  }
}

export default Clients;
