import React from "react";
import ReactHtmlParser from 'react-html-parser';

const ContactContent = props => {
  return (
    <section className={"contact-content " + (props.active ? "display" : "hide")}>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <h2 className="heading">{props.heading}</h2>
            <hr align="left" />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            {ReactHtmlParser(props.contentLeft)}
          </div>
          <div className="col-sm-6">
            {ReactHtmlParser(props.contentRight)}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactContent;
