/*
This component renders a blog post given a slug
*/
import React, { Component } from "react";

import Nav from "../components/Nav";
import TransitionElement from "../components/TransitionElement";
import Header from "../components/Header";
import axios from "axios";
import ReactHtmlParser from 'react-html-parser';
import Partners from "../components/Partners";
import FooterLinks from "../components/FooterLinks";
import Copyright from "../components/Copyright";

import Brands from "../assets/brands.png";
import Vendor from "../assets/vendor.png";
import Evaluation from "../assets/evaluation.png";
import { Helmet } from 'react-helmet';
import MoreInfo from "../components/MoreInfo";
const background1 = {
  backgroundImage: `url(${Brands})`
};
const background2 = {
  backgroundImage: `url(${Vendor})`
};
const background3 = {
  backgroundImage: `url(${Evaluation})`
};



class Blog extends Component {
  constructor() {
    super();
    this.state = {
      active: false,
      loading: true,
      title: "",
      image: "",
      content: ""
    };
  }

  componentDidMount() {
    var newsItems = [];
    const loading = false;
    var self = this;

    let slug = window.location.pathname.split('/')[window.location.pathname.split('/').length -1]

    let url = `https://cms.vervegroupconsulting.com/wp-json/wp/v2/posts?slug=${slug}`;
    console.log(url)
    axios.get(url)
    .then(function(res) {
      if( res.data.length == 0 ) {
        self.setState({ active: true, loading: false, title: "Post not found"});

      }
      else {
        self.setState({ active: true, loading: false, title: res.data[0].acf.title, image: res.data[0].acf.image, content: res.data[0].acf.content });
      }
    })
    .catch(function(err) {
      console.log(err);
    });

  }
  renderContent(image, content) {
    return (
      <div className="col-sm-12">
        <div className="featured-image">
          <img src={image} />
        </div>
        {ReactHtmlParser(content)}
      </div>
    )
  }
  render() {
    const { active, title, image, content } = this.state;
    return (
      <div>
        <Helmet>
          <title>Verve | {title}</title>
           <meta name="og:title" content={title} />
           <meta name="og:image" property="og:image" content={image} />
           <meta name="og:type" content="website" />
        </Helmet>
        <Nav />
        <section className="body-content">
          <TransitionElement mColor={"white"} sColor={"#2EA399"} />
          <Header active={active} height={360} heading={title} smallFont={true} />
          <section className={"media " + (active ? "display" : "hide")}>
            <div className="container">
              <div className="row">
                {!this.state.loading ? this.renderContent(image, content) : null}
              </div>
            </div>
          </section>
          {!this.state.loading && <MoreInfo />}
          <Partners active={active} />
          <FooterLinks active={active} />
          <Copyright active={active}/>
        </section>
      </div>
    );
  }
}

export default Blog;
