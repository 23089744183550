import React, { Component } from "react";

import Nav from "../components/Nav";
import TransitionElement from "../components/TransitionElement";
import Button from "../components/Button";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ContactContent from "../components/contactContent";
import axios from "axios";
import ReactHtmlParser from 'react-html-parser';
class Events extends Component {
  constructor() {
    super();
    this.state = {
      active: false,
      events: []
    };
  }

  componentDidMount() {
    let self = this;
    axios.get('https://cms.vervegroupconsulting.com/wp-json/wp/v2/pages/412')
    .then(function(res) {
      self.setState({
        events: res.data.acf.events,
        active: true
      })
    })
    .catch(function(err) {
      console.log(err);
    });
  }

  render() {
    let { active, events } = this.state;
    let past_events = [];
    let upcoming_events = [];
    events.map(event => {
      console.log(event)
      console.log(Date.parse(event.event_date))
      console.log(Date.now())
      if(Date.parse(event.event_date) < Date.now()) {
        console.log("past")
        past_events.push(event);
      }
      else {
        console.log("upcoming")

        upcoming_events.push(event);
      }
    })

    return (
      <div>
        <Nav />
        <section className="body-content">
          <TransitionElement mColor={"white"} sColor={"#2EA399"} />
          <Header active={active} height={360} heading="Events" />
          <section className="events-section">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="events-container">
                    <h2>Upcoming Events</h2>
                    {upcoming_events.map(({event_info}) => (
                      <div className="event">
                        {ReactHtmlParser(event_info)}
                      </div>
                    ))}
                  </div>
                  <div className="events-container">
                    <h2>Past Events</h2>
                    {past_events.map(({event_info}) => (
                      <div className="event">
                        {ReactHtmlParser(event_info)}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer active={active} />
        </section>
      </div>
    );
  }
}

export default Events;
