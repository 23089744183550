import React from "react";

const Header = props => {
  const activeClass = props.active ? " anim_active" : "";
  return (
    <header className="custom-header" style={{ height: props.height }}>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
          <div className={"animate" + activeClass}>
            <h1 className={"header-heading " + (props.smallFont ? "small-font" : null)}>{props.heading}</h1>
          </div>
          </div>
        </div>
      </div>

    </header>
  );
};

export default Header;
