import React, { Component } from "react";
import Button from "./Button";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import axios from "axios";
class MainContent extends Component {
  constructor() {
    super();
    this.state = {
      title: "",
      subtitle: "",
      content:"",
      card_list: [],
      active: false
    };
  }
  componentDidMount() {
    var self = this;
    var points = [];
    var url = "https://cms.vervegroupconsulting.com/wp-json/wp/v2/pages/" + this.props.id;
    axios.get(url)
    .then(function(res) {
      res.data.acf.points.map( ({point}) => (
        points.push(point)
      ));
      self.setState({
        title: res.data.acf.title,
        subtitle: res.data.acf.subtitle,
        content: res.data.acf.content,
        card_list: points,
        active: true
      })
      self.props.contentLoaded();
    })
    .catch(function(err) {
      console.log(err);
    });
  }
  render() {
    const {
      title,
      subtitle,
      content,
      card_list,
      active
    } = this.state

    return (
      <section className={"main-content " + (active ? "display" : "hide")}>
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-6 main-content-l">
              {title ? <h2>{title}</h2> : null}
              <div className="subtext">{subtitle ? <p>{subtitle}</p> : null}</div>
              <hr align="left" />
              <div className="main">
                {content ? ReactHtmlParser(content) : null}
              </div>
            </div>
            <div className="col-xl-5 col-lg-6 main-content-r">
              <div className="border-card c-col">
                <h2 className="title">The Verve Advantage</h2>
                <div className="list">
                  <ul>
                    {card_list
                      ? card_list.map((text, index) => (
                          <li key={index}>
                            <div className="c-row">
                              <div className="check"></div>
                                {text}
                            </div>
                          </li>
                        ))
                      : null}
                  </ul>
                </div>
              </div>
              <div className="filled-card fill-g c-col">
                <h2 className="title">Want to know more about Verve?</h2>
                <div className="contact-info">
                  <Button
                    className=""
                    link="/contact"
                    text="It all starts with a conversation."
                  />
                  <span><strong>Larry Samilow, Co-Founder and Managing Director</strong></span>
                  <a href="mailto:lsamilow@vervegroupconsulting.com"><strong>lsamilow@vervegroupconsulting.com</strong></a>
                  <a href="tel:908-884-0379"><strong>908-884-0379</strong></a>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default MainContent;
