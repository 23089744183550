import React from "react";

import "./styles/index.scss";

import Views from "./Main";

function App() {
  return (
    <div className="App">
      <Views />
    </div>
  );
}

export default App;
