import React from "react";

const TransitionElement = props => {
  const [mainStyle, secondaryStyle] = [
    {
      backgroundColor: props.mColor
    },
    {
      backgroundColor: props.sColor
    }
  ];
  return (
    <div>
      <div className="row">
        <div
          style={mainStyle}
          className={
            "transition-elem " + (props.className ? props.className : "")
          }
          id="transition-secondary"
        />
        <div
          style={secondaryStyle}
          className={
            "transition-elem " + (props.className ? props.className : "")
          }
          id="transition-main"
        />
      </div>
    </div>
  );
};

export default TransitionElement;
