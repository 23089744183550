import React, { Component } from "react";
import Brands from "../assets/brands.png";
import Vendor from "../assets/vendor.png";
import Evaluation from "../assets/evaluation.png";
import axios from 'axios';
import ReactHtmlParser from 'react-html-parser';
import Button from "./Button";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

class Media extends Component {
  constructor() {
    super();
    this.state = {
      active: false,
      loading: true,
      newsItems: []
    };
  }
  componentDidMount() {
    var newsItems = [];
    const loading = false;
    var self = this;

    axios.get('https://cms.vervegroupconsulting.com/wp-json/wp/v2/posts?categories=2')
    .then(function(res) {
      res.data.map( (data) => (
        newsItems.push({id: data.id, slug: data.slug, title: data.title.rendered, link: data.acf.link, image: data.acf.image, internal: data.acf.internal})
      ));
      self.setState({newsItems, loading});
      self.setState({ active: true });

    })
    .catch(function(err) {
      console.log(err);
    });

  }
  renderExternal(title, link, image) {
    return (
      <a className="media-card-link" target="_blank" href={link}>
        <div className="media-card" style={{backgroundImage: `url(${image})`}}>
          <div className="media-card-overlay" />
          <div className="media-card-content">
            <p>
              {ReactHtmlParser(title)}
            </p>
          </div>
        </div>
      </a>
    )
  }
  renderInternal(title, slug, image) {
    return (
      <Link className="media-card-link" to={"/news/" + slug}>
        <div className="media-card" style={{backgroundImage: `url(${image})`}}>
          <div className="media-card-overlay" />
          <div className="media-card-content">
            <p>
              {ReactHtmlParser(title)}
            </p>
          </div>
        </div>
      </Link>
    )
  }
  renderCards() {
    let self = this;
    let lastIndex = this.state.newsItems.length;
    return (
      this.state.newsItems.slice(0,3).map(({ id, title, slug, link, image, internal }) => (
        <div className="col-lg-4 text-center" key={id}>
          {
            (internal==true ? self.renderInternal(title,slug,image) : self.renderExternal(title,link,image))
          }

        </div>
      ))
    )
  }


  render() {
    const { active,newsItems } = this.state;

    return (
      <section className={"media " + (active ? "display" : "hide")}>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h2>
              <Button text={"Worth Reading..."} link={"/news"} />
              </h2>
            </div>
            {!this.state.loading ? this.renderCards() : null}
          </div>
          <div className="col-sm-12 text-right">
          <Button text={"Read More News"} link={"/news"}/>

          </div>
        </div>
      </section>
    )
  }
}
export default Media;
