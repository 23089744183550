import React, {Component} from "react";
import ReactHtmlParser from "react-html-parser"
import axios from "axios";
class FlipCards extends Component {
  constructor() {
    super();
    this.state = {
      cards: []
    };
  }
  componentDidMount() {
    let self = this;

    axios.get('https://cms.vervegroupconsulting.com/wp-json/wp/v2/pages/59')
    .then(function(res) {
      self.setState({
        cards: res.data.acf.hover_boxes
      })
    })
    .catch(function(err) {
      console.log(err);
    });
  }
  render() {
    return (
      <section className="flip-cards">
        <div className="container">
          <div className="row">
            {
              this.state.cards.map( (card, index) => (
                <div className="col-xl-3 col-lg-6 col-md-6" key={index}>
                  <div className="flip-card">
                    <div className="top">
                      <h3>{card.front}</h3>
                    </div>
                    <div className="bottom">
                      {ReactHtmlParser(card.back)}
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </section>
    )
  }
}


export default FlipCards;
