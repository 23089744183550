import React from "react";
import Button from "./Button";

const linksStyle = {
  fontSize: 24,
  fontWeight: 900,
  fontFamily: "NeueEinstellung",
};

const FooterLinks = props => {
  return (
    <section className={"footer-links " + (props.active ? "display" : "hide")}>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="link-boxes">
              <div className="link-box">
                <Button
                  className=""
                  link="/clients"
                  text="Clients"
                  style={linksStyle}
                  arrow={true}
                />
              </div>
              <div className="link-box">
                <Button
                  className=""
                  link="/consultants"
                  text="Consultants"
                  style={linksStyle}
                  arrow={true}
                />
              </div>
              <div className="link-box">
                <Button link="/news" text="News" style={linksStyle} arrow={true}/>
              </div>

              <div className="link-box">
                <Button link="/contact" text="Contact" style={linksStyle} arrow={true}/>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FooterLinks;
